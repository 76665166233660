import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-booking-modal',
  templateUrl: './booking-modal.component.html',
  styleUrls: ['./booking-modal.component.css']
})
export class BookingModalComponent  {

  constructor(
    public dialogRef: MatDialogRef<BookingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { examOptions: any }
  ) {}

  bookExam(option: string) {
    const url = this.data.examOptions[option];
    if (url) {
      window.open(url, '_blank');  // Open in a new tab
      this.dialogRef.close();
    }

}

getExamKeys(): string[] {
  return this.data && this.data.examOptions ? Object.keys(this.data.examOptions) : [];
}

openExamLink(url: string) {
  window.open(url, '_blank');
}

  ngOnInit() {
  }

}
