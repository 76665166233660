import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FrontLayoutComponent } from './front-layout.component';
import { UpcomingEventsComponent } from './upcoming-events/upcoming-events.component';
import { AcademyComponent } from './academy/academy.component';
import { UAEComponent } from './uae/uae.component';
import { GcseClassesComponent } from '../landingpages/gcse-classes/gcse-classes.component';
import { TimetableComponent } from '../calender/timetable/timetable.component';
import { GcsetimetableComponent } from '../calender/gcsetimetable/gcsetimetable.component';
import { TopicTestComponent } from '../calender/topic-test/topic-test.component';
import { WebinarComponent } from '../landingpages/webinar/webinar.component';
import { ElevplusClassesComponent } from '../landingpages/elevplus-classes/elevplus-classes.component';
import { ProductServicesComponent } from '../landingpages/product-services/product-services.component';
import { ConsortiumComponent } from './consortiums/consortium/consortium.component';
import { PageOneComponent } from './consortiums/page-one/page-one.component';
import { PageCsseComponent } from './consortiums/page-csse/page-csse.component';
import { PageRedbridgeComponent } from './consortiums/page-redbridge/page-redbridge.component';
import { PageBucksComponent } from './consortiums/page-bucks/page-bucks.component';
import { PageW11plusComponent } from './consortiums/page-w11plus/page-w11plus.component';
import { GrammarSchoolsComponent } from './consortiums/grammar-schools/grammar-schools.component';

const routes: Routes = [
  {
    path: '', component: FrontLayoutComponent,
    children: [
      { path: 'sehyog', loadChildren: '../front-layout/sehyog/sehyog.module#SehyogModule' },
      { path: '11plus-help-club', loadChildren: '../front-layout/eleven-plus/eleven-plus.module#ElevenPlusModule' },
      { path: 'become-a-volunteer', loadChildren: '../front-layout/become-volunteer/become-volunteer.module#BecomeVolunteerModule' },
      { path: 'donation', loadChildren: '../front-layout/make-donation/make-donation.module#MakeDonationModule' },
      { path: 'freedemo', loadChildren: '../front-layout/make-donation/make-donation.module#MakeDonationModule' },
      { path: 'request-for-franchise', loadChildren: '../front-layout/request-franchise/request-franchise.module#RequestFranchiseModule' },
      { path: 'contact-us', loadChildren: '../front-layout/contact-us/contact-us.module#ContactUsModule' },
      { path: 'basket', loadChildren: '../front-layout/basket/basket.module#BasketModule'},
      { path: 'payment', loadChildren: '../front-layout/payment/payment.module#PaymentModule'},
      { path: 'support', loadChildren: '../front-layout/support/support.module#SupportModule' },
      { path: 'legal', loadChildren: '../front-layout/legal/legal.module#LegalModule' },
      { path: '11plus-live' , component: UpcomingEventsComponent },
      { path: 'academy' , component: AcademyComponent },
      { path: 'UAE' , component: UAEComponent },
      { path: 'gcse-classes', component: GcseClassesComponent},
      { path: '11Plus-mocktest', component: TimetableComponent},
      {path: 'gcse-mocktest', component: GcsetimetableComponent},
      {path: 'topic-test', component: TopicTestComponent},
      {path: 'webinar', component: WebinarComponent},
      {path: '11Plus-classes', component: ElevplusClassesComponent},
      {path: 'product-services', component: ProductServicesComponent},
      {path: 'consortium', component: ConsortiumComponent},
      {path: 'kent', component: PageOneComponent},
      {path: 'csse-schools', component: PageCsseComponent},
      {path: 'redbridge-schools', component: PageRedbridgeComponent},
      {path: 'bucks-schools', component: PageBucksComponent},
      {path: 'w11plus', component: PageW11plusComponent},
      {path: 'grammar-schools', component: GrammarSchoolsComponent},
      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontLayoutRoutingModule { }
