<div class="details-container"> 
  <div class="details-header" style="background: url(assets/images/csse_sch.jpeg);">
      
      <div class="details-banner">
          <h1>The Consortium of Selective Schools in Essex (CSSE)</h1>
          <h2>11 Plus (11+) Exam Information</h2>
      </div>
  </div>

  <div class="details-publisher">
    <span><strong>Published by - SEHYOG Academy</strong></span>
</div>


<div class="details-content">

  <div class="details-section">
    <h3>Introduction</h3>
    <p>The Consortium of Selective Schools in Essex (CSSE) is a collaborative group of grammar schools in Essex that work together to administer the 11+ entrance examination for admission to their schools. The CSSE aims to ensure a fair and consistent admissions process for selective schools in Essex, allowing students to be considered for multiple schools within the consortium based on their performance in the entrance exam.</p>

    <p>The primary objective of the CSSE Consortium is to improve the effectiveness and equity of the admissions process for its member grammar schools. Through collaboration, the schools within the consortium share resources, expertise and administrative tasks, ensuring a consistent and well-managed selection procedure. This collective endeavour aims to offer students from diverse backgrounds equal opportunities to obtain a first-rate education across the participating grammar schools.</p>

    <div class="spacer"></div>

    <h4>Complete List of Grammar Schools in Essex</h4>

    <div class="spacer"></div>
    <div class="spacer"></div>

    <p>There are currently 10 grammar schools in the Consortium of Selective Schools in Essex (CSSE):</p>

</div>

<table>
  <tr>
    <th>School Name</th>
  </tr>
  <tr *ngFor="let school of schools">
    <td><a [href]="school.url" target="_blank">{{ school.name }}</a></td>
  </tr>
</table>


<div class="details-section">

  <p>Read on to learn more about their exam and admissions process!</p>
  
</div>


<div class="exam-info">
  <h2>11 Plus (11+) Exam Information for Essex (CSSE) Grammar Schools</h2>
  <p>
    The Essex (CSSE) entrance exam represents a common benchmark for admission decisions among the grammar schools in the consortium. Students who demonstrate exceptional performance in the exam have the opportunity to be considered for admission to multiple schools within the consortium, thereby enhancing their prospects of securing a place at a grammar school. Here are key details about the Essex (CSSE) 11+ exam:
  </p>
  <p><strong>Registration closing date:</strong> June</p>
  <p><strong>Common Application Form (CAF) closing date:</strong>October</p>
  <p><strong>Essex 11+ (CSSE) exam date:</strong>September 2025</p>
  <p><strong>Exam board type:</strong> CSSE</p>
  <p><strong>Pass mark:</strong> Aim for 80-85%+ in our practice tests. See below for more details.</p>
  <p><strong>Results date:</strong> October</p>
  <p><strong>Allocations date:</strong> March</p>
  <p>
    <strong>Admissions info:</strong> 
    Essex Council, 0845 603 2200
  </p>
</div>


<div class="details-section">

  <h2>Essex Grammar Schools (CSSE) 11 Plus (11+) Admissions</h2>

  <p>Year 7 admission to all grammar schools in the CSSE are based on pupil performance in the 11 Plus exam. Essex and Southend grammar school admissions are managed by the Consortium of Selective Schools in Essex (CSSE). You can contact them directly via email.</p>

  <p>The Consortium allows students to sit only one exam and be eligible for entry to all the schools on their preference form. All selective places are awarded on the basis of student ability, which is defined by their test score. For more information on the admission criteria to CSSE schools, please see the CSSE guide available on this page.</p>


  <div class="spacer"></div>

  <div class="spacer"></div>

  <h2>How to Register for the Consortium of Grammar Schools in Essex 11 Plus (11+) Exam</h2>

  <p>Before registering for a Year 7 place at a CSSE school, it’s advised that you and your child attend one of your preferred school’s Open Events. Each school has its own event where prospective families can visit the campus, meet staff and learn more about the admissions process.</p>

  <p>To register your child for a Year 7 place at a CSSE school, you will be asked to complete a Supplementary Information Form before the deadline in late June. The form can be completed online (on the CSSE website) or obtained from any CSSE school.</p>

  <p>You also need to complete a Common Application Form (CAF) before October. The form should indicate your preferred school/schools and can be completed online via the Essex Council website.</p>


  <div class="spacer"></div>
  <div class="spacer"></div>

  <h2>Essex Consortium Grammar Schools 11 Plus (11+) Exam Format</h2>

  <p>All CSSE members use the same 11 Plus exam to ensure that students only need to take part in one test. The exam consists of two test papers that cover English and Maths.</p>

  <p>➢ The English paper lasts 60 minutes with ten minutes additional reading time. The CSSE 11+ English paper can include both multiple-choice and open-answer comprehension questions, as well as a writing section.</p>


  <div class="spacer"></div>

  <div><img src="assets/images/check-list.png" alt=""> <p>Multiple-choice Comprehension – this section tests pupils’ ability to analyse and interpret information, make inferences and evaluate options to select the best answer.</p></div>

  <div><img src="assets/images/check-list.png" alt=""> <p>Standard format Comprehension – this section focuses more on pupils’ ability to comprehend, summarise and articulate their understanding of the passage through written responses.</p></div>

  <div><img src="assets/images/check-list.png" alt=""> <p>Continuous Writing – this section evaluates pupils’ ability to structure paragraphs and produce original ideas, as well as use ambitious vocabulary and varied punctuation.</p></div>

  <div class="spacer"></div>


  <p>➢ The Maths paper also lasts 60 minutes and focuses on topics from the KS2 National Curriculum, assessing arithmetic, algebra, geometry, statistics and problem-solving skills. It consists of standard format questions, which means that students have to write in their own answers rather than choose from a list of multiple-choice options.</p>

  <p>Offers are dependent on your child’s age standardised score. However, it should be noted that passing the selection tests is not a guarantee of being offered a place. CSSE exam results are sent to parents by first class post in October.</p>

  <div class="spacer"></div>

  <h2>Essex CSSE 11 Plus (11+) Exam Pass Marks and Target Scores</h2>


  <p>Essex grammar schools assess pupils for Year 7 entry using the CSSE exam. Historical data shows that most schools in the area do not admit applicants who have a standardised score less than 303 in the CSSE.</p>

  <p>Exam results from previous years suggest this correlates with a score of roughly 80-85%+ in our 11+ Essex practice tests.</p>

  <p>Whilst there are no guarantees, this has historically been a fairly strong indicator that your child should do well in the CSSE exam.</p>

  <p>Passing the test does not guarantee that your child will be offered a place. It simply means they have surpassed the required threshold. As such, it makes sense to aim for as high a score as possible.</p>

  <p>Don’t worry if your child is not scoring well in our practice papers from the outset. With targeted practice and focus, it’s possible to make big improvements in a short amount of time!</p>

  <div class="spacer"></div>
  <div class="spacer"></div>

  <h2>The Consortium of Selective Schools in Essex (CSSE): Results & Leavers’ Destinations</h2>

  <div class="spacer"></div>
  <div class="spacer"></div>

  <h3>Essex (CSSE) Grammar Schools – Progress 8 Scores</h3>

  <p>Progress 8 is a metric that evaluates students’ progress from the end of Key Stage 2 in primary school to the end of Key Stage 4 in secondary school. It provides insights into the educational effectiveness of schools by assessing students’ academic development.</p>

  <div class="spacer"></div>

  <p>The Progress 8 score is calculated by comparing students’ actual achievements in eight core subjects, such as English, mathematics, sciences, humanities and languages, with their expected achievements based on their prior performance in primary school. The score represents the average progress students make across these subjects.</p>

  <div class="spacer"></div>

  <p>📊 Please refer to the chart below for details on Progress 8 scores at grammar schools in Essex:</p>

  <img src="assets/images/chart2.png" alt="">

  <div class="spacer"></div>

  <p>A positive Progress 8 score indicates that students, on average, have made more progress than expected, while a negative score suggests that they have made less progress than expected. A score of zero means that students have progressed as expected.</p>

  <div class="spacer"></div>
  <div class="spacer"></div>

  <h3>Essex (CSSE) Grammar Schools – Leavers’ Destinations</h3>

  <p>Leavers’ destinations here refer to the next steps that students take after completing their studies at Essex grammar schools. </p>

  <p>Russell Group universities are widely respected educational institutions, recognised for their exceptional achievements. These universities showcase exceptional research output, housing renowned scholars and groundbreaking discoveries. Their academic standing is exemplary, attracting talented individuals from across the globe.</p>

  <p>Among the esteemed members of the Russell Group are the University of Oxford and the University of Cambridge, both renowned for their prestigious history, academic excellence and rigorous educational programs. With distinguished faculty and a commitment to excellence, these universities have maintained their reputation for centuries.</p>

  <p>📊 The chart below shows the recent leavers’ destinations for grammar schools in Essex:</p>


  <img src="assets/images/chart3.png" alt="">


  <p>As the chart above shows, a significant number of students from The Consortium of Selective Schools in Essex are offered places at top universities in the UK.</p>

  <h2>Frequently Asked Questions About The 11 Plus (11+) Exam at The Consortium of Selective Schools in Essex</h2>

  <div class="spacer"></div>
  <div class="spacer"></div>

  <p>Does Essex have grammar schools?</p>

  <p>Yes, Essex has ten selective grammar schools. They all use the same 11+ exam to assess Year 7 applicants. Click here to access the articles we have for each of these schools, addressing their history and ethos, as well as all the details about their 11+ entry procedure.</p>


  <div class="spacer"></div>
  <div class="spacer"></div>

  <p>What is CSSE?</p>

  <p>CSSE stands for Consortium of Selective Schools in Essex. It is a collaboration of grammar schools in Essex who administer the 11+ entrance examination for admission to their schools. The CSSE exam is taken by students in Year 6 (ages 10-11) who are seeking entry into selective grammar schools in the region.</p>

  <div class="spacer"></div>
  
  <p>When should I register my child for the Essex CSSE 11+ exam?</p>

  <p>The deadline for submissions of the  Supplementary Information Form (SIF) is in late June. You will also be asked to complete a Common Application Form (CAF) before the closing date in October.</p>

  <div class="spacer"></div>
  <div class="spacer"></div>

  <p>When are the 11+ exams held for Essex (CSSE) grammar schools?</p>

  <p>The Essex CSSE 11+ exam in held in September.</p>

  <div class="spacer"></div>
  <div class="spacer"></div>

  <p>Which areas does the Essex CSSE 11+ exam assess?</p>

  <p>
    The Essex CSSE 11+ exam consists of two tests that assess English (comprehension and creative writing) and Mathematics.</p>

    <div class="spacer"></div>

    <p>Historical data shows that most schools in the area do not admit applicants who have a standardised score less than 303 in the CSSE. Exam results from previous years suggest this correlates with a score of roughly 80-85%+ in our 11+ Essex practice tests.</p>

    <div class="spacer"></div>

    <p>Who is the 11+ exam provider for Essex?</p>

    <p>Schools in the Essex Consortium use the CSSE exam board.</p>





</div>






</div>