/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./consortium.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./consortium.component";
import * as i4 from "@angular/router";
var styles_ConsortiumComponent = [i0.styles];
var RenderType_ConsortiumComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConsortiumComponent, data: {} });
export { RenderType_ConsortiumComponent as RenderType_ConsortiumComponent };
function View_ConsortiumComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "exam-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "exam-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "read-more"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openExam(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Read More"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "exam-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.description; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.image; var currVal_3 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.title, ""); _ck(_v, 9, 0, currVal_2, currVal_3); }); }
export function View_ConsortiumComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "cs-head"], ["style", "background:url(assets/images/c_bg.jpg);"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["11+ Consortiums"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "exam-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConsortiumComponent_1)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.examList; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_ConsortiumComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-consortium", [], null, null, null, View_ConsortiumComponent_0, RenderType_ConsortiumComponent)), i1.ɵdid(1, 180224, null, 0, i3.ConsortiumComponent, [i4.Router], null, null)], null, null); }
var ConsortiumComponentNgFactory = i1.ɵccf("app-consortium", i3.ConsortiumComponent, View_ConsortiumComponent_Host_0, {}, {}, []);
export { ConsortiumComponentNgFactory as ConsortiumComponentNgFactory };
