/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./grammar-schools.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./grammar-schools.component";
var styles_GrammarSchoolsComponent = [i0.styles];
var RenderType_GrammarSchoolsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GrammarSchoolsComponent, data: {} });
export { RenderType_GrammarSchoolsComponent as RenderType_GrammarSchoolsComponent };
function View_GrammarSchoolsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "school-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "visit-btn"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Visit"]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.website; _ck(_v, 3, 0, currVal_1); }); }
export function View_GrammarSchoolsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "cs-head"], ["style", "background:url(assets/images/c_bg.jpg);"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Grammar Schools "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "school-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GrammarSchoolsComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.schools; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_GrammarSchoolsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-grammar-schools", [], null, null, null, View_GrammarSchoolsComponent_0, RenderType_GrammarSchoolsComponent)), i1.ɵdid(1, 114688, null, 0, i3.GrammarSchoolsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GrammarSchoolsComponentNgFactory = i1.ɵccf("app-grammar-schools", i3.GrammarSchoolsComponent, View_GrammarSchoolsComponent_Host_0, {}, {}, []);
export { GrammarSchoolsComponentNgFactory as GrammarSchoolsComponentNgFactory };
