import { Component, OnInit } from '@angular/core';

interface School {
  name: string;
  website: string;
}

@Component({
  selector: 'app-grammar-schools',
  templateUrl: './grammar-schools.component.html',
  styleUrls: ['./grammar-schools.component.css']
})
export class GrammarSchoolsComponent implements OnInit {

  constructor() { }

  schools: School[] = [
    // London
    { name: "The Henrietta Barnett School", website: "https://www.hbschool.org.uk" },
    { name: "Queen Elizabeth's School, Barnet", website: "https://www.qebarnet.co.uk" },
    { name: "Wilson's School", website: "https://www.wilsonsschool.sutton.sch.uk" },
    { name: "St Olave's Grammar School", website: "https://www.saintolaves.net" },
    { name: "The Latymer School", website: "https://www.latymer.co.uk" },

    // South East England
    { name: "Reading School", website: "https://www.reading-school.co.uk" },
    { name: "Kendrick School", website: "https://www.kendrick.reading.sch.uk" },
    { name: "Tonbridge Grammar School", website: "https://www.tgs.kent.sch.uk" },
    { name: "The Judd School", website: "https://www.judd.online" },
    { name: "Dartford Grammar School", website: "https://www.dartfordgrammarschool.org.uk" },

    // South West England
    { name: "Pate's Grammar School", website: "https://www.patesgs.org" },
    { name: "Colyton Grammar School", website: "https://www.colytongrammar.com" },
    { name: "Torquay Boys' Grammar School", website: "https://www.tbgs.co.uk" },
    { name: "Torquay Girls' Grammar School", website: "https://www.tggsacademy.org" },
    { name: "Bournemouth School", website: "https://www.bournemouth-school.org" },

    // Midlands
    { name: "King Edward VI Camp Hill School for Boys", website: "https://www.camphillboys.bham.sch.uk" },
    { name: "King Edward VI Camp Hill School for Girls", website: "https://www.kechg.org.uk" },
    { name: "Queen Mary's Grammar School", website: "https://www.qmgs.walsall.sch.uk" },
    { name: "Queen Mary's High School", website: "https://www.qmhs.org.uk" },
    { name: "Lawrence Sheriff School", website: "https://www.lawrencesheriffschool.net" },

    // Northern England
    { name: "Altrincham Grammar School for Boys", website: "https://www.agsb.co.uk" },
    { name: "Altrincham Grammar School for Girls", website: "https://www.aggs.bright-futures.co.uk" },
    { name: "Lancaster Royal Grammar School", website: "https://www.lrgs.org.uk" },
    { name: "Lancaster Girls' Grammar School", website: "https://www.lggs.org.uk" },
    { name: "Ripon Grammar School", website: "https://www.ripongrammar.co.uk" },

    // Northern Ireland
    { name: "Belfast Royal Academy", website: "https://www.belfastroyalacademy.com" },
    { name: "Methodist College Belfast", website: "https://www.methody.org" },
    { name: "Royal Belfast Academical Institution", website: "https://www.rbai.org.uk" },
    { name: "Sullivan Upper School", website: "https://www.sullivanupper.co.uk" },
    { name: "The Wallace High School", website: "https://www.wallacehigh.org" }
  ];

  ngOnInit() {
  }

}
