import { Component, OnInit } from '@angular/core';
import { TimetableService } from '../services/gcse.data.service';
import { MonthTimetable } from '../models/event.model';
import { BookingModalComponent } from './booking-modal/booking-modal.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-gcsetimetable',
  templateUrl: './gcsetimetable.component.html',
  styleUrls: ['./gcsetimetable.component.css']
})
export class GcsetimetableComponent implements OnInit {

  months: string[] = [];
    selectedMonth: string = 'February';
    timetable: MonthTimetable = {};
  
    constructor(private timetableService: TimetableService, private dialog: MatDialog) {}
  
    ngOnInit() {
      this.months = this.timetableService.getMonths();
      this.timetable = this.timetableService.getTimetable();
    }
  
    selectMonth(month: string) {
      this.selectedMonth = month;
    }

    openBookingPopup(examOptions: any) {
      this.dialog.open(BookingModalComponent, {
        data: { examOptions }
      });
    }

}
