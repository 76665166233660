<div class="details-container"> 
  <div class="details-header" style="background: url(assets/images/sch_2.jpg);">
      
      <div class="details-banner">
          <h1>Kent Grammar Schools</h1>
          <h2>11 Plus (11+) Exam Information</h2>
      </div>
  </div>
  <div class="details-publisher">
    <span><strong>Published by - SEHYOG Academy</strong></span>
</div>

  <div class="details-content">
      

      <div class="details-section">
          <h3>Introduction</h3>
          <p>The Consortium of Selective Schools in Essex (CSSE) is a collaborative group of grammar schools in Essex that 
              work together to administer the 11+ entrance examination for admission to their schools. The CSSE aims to 
              ensure a fair and consistent admissions process for selective schools in Essex, allowing students to be 
              considered for multiple schools within the consortium based on their performance in the entrance exam.</p>

          <p>The primary objective of the CSSE Consortium is to improve the effectiveness and equity of the admissions 
              process for its member grammar schools. Through collaboration, the schools within the consortium share 
              resources, expertise and administrative tasks, ensuring a consistent and well-managed selection procedure. 
              This collective endeavour aims to offer students from diverse backgrounds equal opportunities to obtain a 
              first-rate education across the participating grammar schools.</p>
      </div>

      

      <h3 style="align-self: self-start;">Complete List of Grammar Schools in Kent</h3>

      <h4 style="align-self: self-start;">There are currently 32 grammar schools in the Kent Grammar Schools Consortium:</h4>


      <table>
        <tr>
          <th>School Name</th>
        </tr>
        <tr *ngFor="let school of schools">
          <td><a [href]="school.url" target="_blank">{{ school.name }}</a></td>
        </tr>
      </table>


      <div class="exam-info">
        <h2>11 Plus (11+) Exam Information for Kent Grammar Schools</h2>
        <p>
          The Kent 11+ exam serves as a shared standard for admission decisions among the grammar 
          schools in the consortium. Students only need to take one exam. Those who do well have 
          the chance to be considered for admission to multiple schools within the consortium, 
          increasing their chances of securing a place at a grammar school. Here are important 
          details about the Kent 11+ exam:
        </p>
        <p><strong>Registration closing date:</strong> July</p>
        <p><strong>Secondary Common Application Form (SCAF) closing date:</strong> October</p>
        <p><strong>Exam date:</strong> September</p>
        <p><strong>Exam board type:</strong> GL Assessment</p>
        <p><strong>Pass mark:</strong> Aim for 75%+ in our practice tests. See below for more details.</p>
        <p><strong>Results date:</strong> October</p>
        <p><strong>Allocations date:</strong> March</p>
        <p>
          <strong>Admissions info:</strong> 
          <a href="https://www.kent.gov.uk/" target="_blank">Kent County Council</a>, 03000 412121
        </p>
      </div>

      <div class="details-sec-2">

        <h2 style="align-self: self-start;">Kent Grammar Schools 11 Plus (11+) Admissions</h2>

      <p>To be considered for a Year 7 place at any Kent grammar school, your child must sit the Kent eleven plus test and achieve the required standard.</p>

      <p>Before applying for a place, you are kindly advised to attend the relevant school’s open events, during which you and your child will tour facilities and ask questions about the admissions process.</p>

      <p>Year 7 admissions to Kent grammar schools are coordinated through Kent County Council and parents must register online ahead of their child’s 11 Plus exam date in September.</p>


      <div style="height: 50px;"></div>

      <h2 style="align-self: self-start;">Kent Grammar Schools 11 Plus (11+) Admissions</h2>

      <p>In the first instance, you must register your child to sit the Kent Test prior to the July deadline. This can be done online via Kent County Council. Once your child has taken the test in September, you will receive their results in October and must then submit the Secondary School Common Application Form (SCAF), again via the Kent local authority website, indicating your child’s preferred school/schools.</p>

      </div>

      
      
      <div class="box-2">

        <h2>Kent Grammar Schools 11 Plus (11+) Exam Format</h2>

        <p>The Kent Test consists of two multiple choice tests:</p>

        <div style="height: 20px;"></div>


        <ol>
            <li><p>An hour-long exam divided into two sections that cover English and Mathematics. Each section has a 5-minute practice exercise and a 25-minute test.</p></li>

            <li><p>An hour-long test that focuses on Reasoning. This paper is divided into three sections:</p></li>
           
        </ol>

        <div style="height: 20px;"></div>

        <div><img src="assets/images/check-list.png" alt=""> <p>Verbal Reasoning</p></div>

        <div><img src="assets/images/check-list.png" alt=""> <p>Non-Verbal Reasoning</p></div>

        <div><img src="assets/images/check-list.png" alt=""> <p>Spatial Reasoning</p></div>

        <div style="height: 20px;"></div>


        <p>Pupils are also required to complete a 40-minute writing task. This does not form part of the final 11 Plus result but exists in case of borderline or appeal cases.</p>

      </div>




      <div class="details-sec-3">

        <h2>Kent 11 Plus (11+) Exam Pass Marks and Target Scores</h2>

        <p>Grammar schools in Kent use a demanding 11+ assessment to target pupils that are in the top 25% of the ability range. Hence, the majority of schools admit students purely on the basis of exam scores.</p>

        <p>Following the exam, your child will receive three standardised scores, one for English, one for Mathematics, and one total score including Reasoning. Standardised scores are used to compare your child’s performance to the average performance of other students taking the test:</p>


        <div class="spacer"></div>

        <div><img src="assets/images/check-list.png" alt=""> <p>Single standardised scores range from 69 to 141;</p></div>

        <div><img src="assets/images/check-list.png" alt=""> <p>The highest possible total standardised score is 423.</p></div>

        <p>In order to pass the exam, your child’s single score must be over 109 and their total score must be 332 or more. [1]
        </p>

        <p>Based on historical data, we have found that students who are able to score 75%+ consistently in our 11+ Kent practice tests are on track to pass the Kent Test. 
        </p>


        <p>Whilst there are no guarantees, this has historically been a fairly strong indicator that your child should do well in the Kent 11+ exam.</p>

        <p>Passing the test does not guarantee that your child will be offered a place. It simply means they have surpassed the required threshold. As such, it makes sense to aim for as high a score as possible.</p>

        <p>Don’t worry if your child is not scoring well in our practice papers from the outset. With targeted practice and focus, it’s possible to make big improvements in a short amount of time!
        </p>

        <div class="spacer"></div>

        <h2>Kent Grammar Schools: Leavers’ Destinations</h2>

        <p>Leavers’ destinations here refer to the next steps that students take after completing their studies at Kent grammar schools. 
        </p>


        <p>Russell Group universities are highly regarded educational institutions, renowned for their exceptional accomplishments. These universities demonstrate outstanding research output, and host esteemed scholars and pioneering discoveries. Their academic reputation is exemplary, attracting talented individuals from around the world.</p>


        <p>Among the distinguished members of the Russell Group are the University of Oxford and the University of Cambridge, both celebrated for their prestigious heritage, academic excellence and rigorous educational programmes. With renowned faculty and a dedication to excellence, these universities have upheld their esteemed reputation for centuries.
        </p>

        <p>The charts below show the recent leavers’ destinations for grammar schools in Kent:
        </p>


        <img src="assets/images/chart.png" class="chart-img" alt="">

        <p>Source: <a href="https://www.compare-school-performance.service.gov.uk"> GOV.UK</a></p>

        <p>As the charts above show, a significant number of students from Kent Grammar School Consortium are offered places at top universities in the UK.</p>





      </div>

      <div class="spacer"></div>

      <h2>Frequently Asked Questions About The 11 Plus (11+) Exam in Kent</h2>
     

      <div class="spacer"></div>


      <div class="faq-container">
        <div class="faq-item" *ngFor="let item of faqItems">
          <div class="faq-question" (click)="toggle(item)">
            <h3>{{ item.question }}</h3>
            <span class="arrow" [ngClass]="{'rotated': item.open}">&#9662;</span>
          </div>
          <div class="faq-answer" [@toggleAnimation]="item.open ? 'open' : 'closed'">
            <p>{{ item.answer }}</p>
          </div>
        </div>
      </div>

      <div>

      </div>


  </div>

  </div>
