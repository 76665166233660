import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FrontLayoutRoutingModule } from './front-layout-routing.module';
import { FrontLayoutComponent } from './front-layout.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { CmsserviceService} from '../common-cms-service/cmsservice.service';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { OwlModule } from 'ngx-owl-carousel';
import { UpcomingEventsComponent } from './upcoming-events/upcoming-events.component';
import { AcademyComponent } from './academy/academy.component';
import { UAEComponent } from './uae/uae.component';
import { GcseClassesComponent } from '../landingpages/gcse-classes/gcse-classes.component'; //new component 
import { TimetableComponent } from '../calender/timetable/timetable.component';
import { GcsetimetableComponent } from '../calender/gcsetimetable/gcsetimetable.component';
import { TopicTestComponent } from '../calender/topic-test/topic-test.component';
import { WebinarComponent } from '../landingpages/webinar/webinar.component';
import { ElevplusClassesComponent } from '../landingpages/elevplus-classes/elevplus-classes.component';
import { ProductServicesComponent } from '../landingpages/product-services/product-services.component';
import { CountUpDirective } from '../count-up.directive';
import { ConsortiumComponent } from './consortiums/consortium/consortium.component';
import { PageOneComponent } from './consortiums/page-one/page-one.component';
import { PageCsseComponent } from './consortiums/page-csse/page-csse.component';
import { PageRedbridgeComponent } from './consortiums/page-redbridge/page-redbridge.component';
import { PageBucksComponent } from './consortiums/page-bucks/page-bucks.component';
import { PageW11plusComponent } from './consortiums/page-w11plus/page-w11plus.component';
import { GrammarSchoolsComponent } from './consortiums/grammar-schools/grammar-schools.component';

@NgModule({
  declarations: [FrontLayoutComponent, 
    HeaderComponent, 
    FooterComponent, 
    HomeComponent, 
    UpcomingEventsComponent, 
    AcademyComponent, 
    UAEComponent, 
    GcseClassesComponent, 
    TimetableComponent,
    GcsetimetableComponent,
    TopicTestComponent,
    WebinarComponent,
    ElevplusClassesComponent,
    ProductServicesComponent,CountUpDirective, ConsortiumComponent, PageOneComponent, PageCsseComponent, PageRedbridgeComponent, PageBucksComponent, PageW11plusComponent, GrammarSchoolsComponent,],
  imports: [
    CommonModule,
    HttpClientModule,
    OwlModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    FrontLayoutRoutingModule,
  ],
  providers: [ CmsserviceService, CookieService ],
  exports: [HeaderComponent, FooterComponent]
})
export class FrontLayoutModule { }
