import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-bucks',
  templateUrl: './page-bucks.component.html',
  styleUrls: ['./page-bucks.component.css']
})
export class PageBucksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  schools =[
    {name: 'Aylesbury High School', url: 'https://www.ahs.bucks.sch.uk/'},
    {name: 'Wycombe High School', url: 'https://www.whs.bucks.sch.uk/'},
    {name: 'The Royal Latin School', url: 'https://www.royallatin.org/'},
    {name: 'Beaconsfield High School', url: 'http://www.beaconsfieldhigh.bucks.sch.uk/'},
    {name: 'SDr Challoner’s High School', url: 'https://www.challonershigh.com/'},
    {name: 'Burnham Grammar School', url: 'https://www.burnhamgrammar.org.uk/'},
    {name: 'Chesham Grammar School', url: 'https://www.cheshamgrammar.org/'},
    {name: 'Aylesbury Grammar School', url: 'https://www.ags.bucks.sch.uk/'},
    {name: 'Dr Challoner’s Grammar School', url: 'https://www.challoners.com/'},
    {name: 'John Hampden Grammar School', url: 'https://www.jhgs.bucks.sch.uk/'},
    {name: 'Sir Henry Floyd Grammar School', url: 'https://www.sirhenryfloyd.co.uk/'},
    {name: 'The Royal Grammar School, High Wycombe', url: 'https://www.rgshw.com/'},
  ]

}
