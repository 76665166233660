<div class="details-container"> 

<div class="details-header" style="background: url(assets/images/redb-sch2.jpg); background-repeat: no-repeat;">
      
      <div class="details-banner">
          <h1>Redbridge, Essex, Grammar Schools</h1>
          <h2>11 Plus (11+) Exam Information</h2>
      </div>
</div>
<div class="details-publisher">
    <span><strong>Published by - SEHYOG Academy</strong></span>
</div>

<div class="details-content">

  <div class="details-section">
    <h3>Introduction</h3>
    <p>In the London Borough of Redbridge, Essex, there are two well-known grammar schools. Both offer a similarly high level of education and provide a wealth of academic and non-academic opportunities, enabling students to fulfil their ambitions.</p>
  
    <p>Also, both of these schools have more applicants than places available, mainly due to their increasing popularity among local families. They consistently produce strong examination results, highlighting their focus on quality education.</p>
  
    <div class="spacer"></div>
    <p>The schools in Redbridge are as follows:</p>
  
    <div class="spacer"></div>

  
    <table>
      <tr>
        <th>School Name</th>
      </tr>
      <tr *ngFor="let school of schools">
        <td><a [href]="school.url" target="_blank">{{ school.name }}</a></td>
      </tr>
    </table>


    <p>Students at these schools demonstrate exceptional proficiency across the curriculum. This helps pave their way for seamless progression into higher education, offering them diverse opportunities in various areas of study.</p>
  
    <div class="spacer"></div>
    <p>⬇️ Read on to learn more about these schools’ exam and admissions process:</p>
  
    <div class="spacer"></div>
    <div class="spacer"></div>
  
    <h2>11 Plus (11+) Exam Information for Redbridge Grammar Schools</h2>
  
    <div class="spacer"></div>
  
    <p>Being part of Redbridge’s grammar schools broadens students’ pathways for multiple university programmes, emphasising the institutions’ effective educational methodologies.</p>
  
    <p>Their rigorous admissions procedure guarantees that each admitted student aligns well with the enriching learning environment and the inspiring curricular and co-curricular experiences they provide. Details about the schools’ admissions process are available below:</p>
  
    <div class="spacer"></div>
  
    <p><strong>Registration closing date: </strong> June</p>
    <p><strong>Common Application Form (CAF) closing date:</strong> October</p>
    <p><strong>Exam date:</strong> 20th September 2024</p>
    <p><strong>Exam board type: </strong> GL Assessment</p>
    <p><strong>Pass mark: </strong> Aim for 90%+ in our practice tests. See below for more details.</p>
    <p><strong>Results date: </strong> October</p>
    <p><strong>Admissions info: </strong> <a href="https://www.redbridge.gov.uk/schools/redbridge-11-plus/">London Borough of Redbridge</a>, 0208 554 5000</p>

    <div class="spacer"></div>

    <h2>Redbridge Grammar Schools 11 Plus (11+) Admissions</h2>

    <div class="spacer"></div>

    <p>All Year 7 places at Redbridge grammar schools are allocated based on pupil performance in the eleven plus (11 Plus) entrance exam. Both schools in the region have limited places and are often oversubscribed. Under such circumstances, the schools use the following oversubscription criteria, prioritising:</p>

    <ul style="list-style:circle">
      <li><p>Looked After Children or previously Looked After Children who live in the catchment area</p></li>
      <li><p>Children who live in the catchment area and are eligible for Pupil Premium</p></li>
      <li><p>Other children who live in the catchment area, ranked according to their standardised 11+ score</p></li>
    </ul>

    <p>Prior to applying for a Year 7 place, you are advised to visit your chosen school together with your child to better understand what they have to offer, their ethos and their educational philosophy. </p>

    <p>Year 7 admissions to Redbridge grammar schools are managed by the local authority (LA), the London Borough of Redbridge (LBR), and you must register online with LBR on behalf of your child ahead of their 11+ exam date in September. Please note that if your child does not live in the catchment area, you are strongly advised not to apply for one of these schools. Historically, children outside of the catchment area have never been offered a place.</p>

    <div class="spacer"></div>

    <div class="spacer"></div>

    <h2>How to Register for the Redbridge Grammar Schools 11 Plus (11+) Exam</h2>

    <div class="spacer"></div>

    <p>To apply for a Year 7 place at a Redbridge grammar school on behalf of your child, you should complete the registration form available on the Local Authority website by the June deadline.</p>

    <div class="spacer"></div>

    <p>Please note that you must also complete a Common Application Form (CAF) and submit it to the Local Authority by the September deadline.</p>


    <div class="spacer"></div>

    <div class="spacer"></div>

    <h2>Redbridge Grammar Schools 11 Plus (11+) Exam Format</h2>

    <div class="spacer"></div>

    <p>The Redbridge 11+ exam consists of two test papers, covering:</p>

    <div class="spacer"></div>

    <div><img src="assets/images/check-list.png" alt=""> <p>English and Verbal Reasoning</p></div>
    <div><img src="assets/images/check-list.png" alt=""> <p>Mathematics and Non-Verbal Reasoning (possibly including Spatial Reasoning questions)</p></div>

    <div class="spacer"></div>

    <p>⇒ The English sections assess skills in English comprehension, vocabulary, punctuation, grammar and spelling.</p>

    <p>⇒ The Maths sections assess students’ mathematical skills, problem-solving abilities and logical reasoning, including questions on operations, algebra, geometry, measurement, statistics and data handling.</p>

    <p>⇒ The Verbal Reasoning test assesses pupils’ ability to identify patterns, complete analogies, solve codes and make logical deductions. </p>

    <p>⇒ The Non-Verbal Reasoning (NVR) sections test students’ ability to solve problems using visual and abstract reasoning.  </p>

    <p>⇒ The Spatial Reasoning sections are designed to assess students’ ability to understand and manipulate visual representations of objects and space. </p>

    <p>The Redbridge 11+ exam is multiple choice, and results are fully standardised and age-weighted.</p>



    <div class="spacer"></div>
    <div class="spacer"></div>
    <h2>Redbridge 11 Plus (11+) Exam Pass Marks and Target Scores</h2>

    <div class="spacer"></div>
    <div class="spacer"></div>

    <p>The grammar schools in the London Borough of Redbridge use a GL Assessment 11+ exam to assess Year 7 entry applicants. Data from previous years suggests that pupils that are able to score 90%+ consistently in our 11+ practice tests should be on track to pass this 11+ GL Assessment created test.</p>


    <p>Whilst there are no guarantees, this is a fairly strong indicator that your child should do well in the Redbridge 11+ exam.</p>

    <p>Passing the test does not guarantee that your child will be offered a place; it simply means they have surpassed the required threshold. As such, it makes sense to aim for as high a score as possible.
    </p>


    <div class="spacer"></div>
    <div class="spacer"></div>


    <h2>Redbridge Grammar Schools: Results & Leavers’ Destinations </h2>


    <h4>Redbridge Grammar Schools – Progress 8 Scores</h4>

    <p>Progress 8 scores gauge a student’s academic development from the end of primary school to the end of secondary school. It’s a metric to judge a school’s performance by evaluating student progress. The score is derived by comparing actual grades in eight main subjects, including English, maths, sciences, humanities and languages, to predicted ones from primary school achievements, highlighting the average advancement made.</p>


    <p>📊 Please refer to the chart below for details on Progress 8 scores at selective schools in Redbridge:</p>

    <img src="assets/images/chart-rb.png" alt="">

    
    <p>A positive Progress 8 score indicates that students, on average, have made more progress than expected, while a negative score suggests that they have made less progress than expected. A score of zero means that students have progressed as expected.</p>

    <div class="spacer"></div>

    <h4>Redbridge Grammar Schools: Leavers’ Destinations </h4>

    <p>Leavers’ destinations here refers to the next steps that students take after completing their studies at grammar schools in Redbridge. </p>

    <p>The Russell Group, a collection of high-performing universities, is recognised globally for its pioneering research and sterling academic contributions across various disciplines. </p>

    <p>Noteworthy institutions within this esteemed group, such as the University of Oxford and the University of Cambridge, are held in high regard, not just for their rich historical traditions but also for their cutting-edge innovations. </p>

    <p>The presence of world-class faculties, coupled with rigorous academic programmes, ensures that these universities maintain their high standards and esteemed reputations. This dedication to excellence, both in teaching and research, has firmly established the Russell Group as a beacon of academic brilliance on the international stage.</p>

    <p>📊 The chart below shows the recent leavers’ destinations for selective schools in Redbridge:</p>

    <img src="assets/images/chart-2-rb.png" alt="">

    <p>As the chart above shows, a significant number of students from the Redbridge-based grammar schools are offered places at top universities in the UK.</p>

    <div class="spacer"></div>

   <h2>Frequently Asked Questions About 11 Plus (11+) Entry to Redbridge Grammar Schools</h2>

   <div class="spacer"></div>

   <div class="spacer"></div>

   <p><strong>Does Redbridge have grammar schools?</strong></p>
   <p>The registration deadline for grammar schools in Redbridge is in June. Registrations are completed through the website of the Local Authority, the London Borough of Redbridge. </p>


   <div class="spacer"></div>

   <p> <strong>What is the Redbridge 11 Plus pass mark? </strong>
    <br> 
    <br>Data from previous years suggests that pupils that are able to score 90%+ consistently in our 11+ practice tests should be on track to pass this 11+ GL Assessment created test. Whilst there are no guarantees, this is a fairly strong indicator that your child should do well in the Redbridge 11+ exam. Click here to learn more.</p>


    <div class="spacer"></div>

   <p> <strong>What is the Redbridge 11 Plus pass mark? </strong>
    <br> 
    <br>Data from previous years suggests that pupils that are able to score 90%+ consistently in our 11+ practice tests should be on track to pass this 11+ GL Assessment created test. Whilst there are no guarantees, this is a fairly strong indicator that your child should do well in the Redbridge 11+ exam. Click here to learn more.</p>


    <div class="spacer"></div>

   <p> <strong>Is GL the 11+ exam provider for Redbridge? </strong>
    <br> 
    <br>Yes, grammar schools in Redbridge use GL Assessment as their 11+ exam provider.</p>









    
  
  </div>

</div>


</div>

