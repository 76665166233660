/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./booking-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./booking-modal.component";
import * as i4 from "@angular/material/dialog";
var styles_BookingModalComponent = [i0.styles];
var RenderType_BookingModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BookingModalComponent, data: {} });
export { RenderType_BookingModalComponent as RenderType_BookingModalComponent };
function View_BookingModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "exam-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openExamLink(_co.data.examOptions[_v.context.$implicit]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
export function View_BookingModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Select Your Board"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "button-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BookingModalComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getExamKeys(); _ck(_v, 5, 0, currVal_0); }, null); }
export function View_BookingModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-booking-modal", [], null, null, null, View_BookingModalComponent_0, RenderType_BookingModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.BookingModalComponent, [i4.MatDialogRef, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BookingModalComponentNgFactory = i1.ɵccf("app-booking-modal", i3.BookingModalComponent, View_BookingModalComponent_Host_0, {}, {}, []);
export { BookingModalComponentNgFactory as BookingModalComponentNgFactory };
