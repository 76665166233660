import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-csse',
  templateUrl: './page-csse.component.html',
  styleUrls: ['./page-csse.component.css']
})
export class PageCsseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  schools = [
    { name: 'Highsted School', url: 'https://www.highsted.kent.sch.uk' },
    { name: 'St. Thomas More High School', url: 'https://www.st-thomasmore.southend.sch.uk'},
    { name: 'Westcliff High School for Girls', url: 'https://www.whsg.info/#'},
    { name: 'Westcliff High School for Boys', url: 'https://www.whsb.essex.sch.uk/'},
    { name: 'Southend High School for Girls', url: 'https://www.shsb.org.uk/'},
    { name: 'Southend High School for Boys', url: 'https://www.shsb.org.uk/'},
    { name: 'King Edward VI Grammar School', url: 'https://www.kegs.org.uk/'},
    { name: 'St Bernard’s High School for Girls', url: 'https://www.stbernardswestcliff.org.uk/'},
    { name: 'Colchester Royal Grammar School', url: 'https://www.crgs.co.uk/'},
    { name: 'Colchester County High School for Girls', url: 'https://www.cchsg.com/'}
  ]

}
