<div class="modal-container">
  <h2 class="title">Select Your Board</h2>
  <div class="button-container">
    <button *ngFor="let exam of getExamKeys()" 
            (click)="openExamLink(data.examOptions[exam])"
            class="exam-button">
      {{ exam }}
    </button>
  </div>
</div>
