<div class="details-container">

  <div class="details-header" style="background: url(assets/images/bks-sch.jpeg);">
      
    <div class="details-banner">
        <h1>Buckinghamshire Grammar School Consortium</h1>
        <h2>11 Plus (11+) Exam Information</h2>
    </div>
</div>


<div class="details-publisher">
  <span><strong>Published by - SEHYOG Academy</strong></span>
</div>

<div class="details-content">

  <div class="details-section">

    <h3>Introduction</h3>

    <div class="spacer"></div>

    <p>The Buckinghamshire Grammar School Consortium was established with the purpose of coordinating the 11+ secondary school entrance examination process in Buckinghamshire. Its objective is to ensure a fair and consistent admissions process for all selective grammar schools in the county. Students who take the 11+ entrance exam have the opportunity to be considered for multiple schools within the consortium based on their performance.</p>

    <div class="spacer"></div>

    <p>The consortium is dedicated to improving the efficiency and fairness of the admissions process for its member schools. Through collaboration, the schools share resources, expertise and administrative responsibilities, resulting in a unified and well-organised selection procedure. This collective effort aims to provide students from diverse backgrounds with equal opportunities to access high-quality education across the participating grammar schools.</p>

    
    <div class="spacer"></div>

    <h4>Complete List of Grammar Schools in Bucks</h4>

    
    <div class="spacer"></div>

    <p>There are currently 13 grammar schools in the Buckinghamshire Grammar School Consortium:</p>

    <div class="spacer"></div>

    <table>
      <tr>
        <th>School Name</th>
      </tr>
      <tr *ngFor="let school of schools">
        <td><a [href]="school.url" target="_blank">{{ school.name }}</a></td>
      </tr>
    </table>

    <div class="spacer"></div>

    <p>⬇️ Read on to learn more about their exam and admissions process!</p>

    <div class="spacer"></div>
    <div class="spacer"></div>

    <h2>11 Plus (11+) Exam Information for Buckinghamshire Grammar Schools</h2>

    <div class="spacer"></div>

    <p>The Bucks 11+ exam is a common benchmark for admission decisions amongst the grammar schools in the consortium. With just one exam, students have the opportunity to be considered for admission to multiple schools within the consortium, increasing their chances of securing a place at a grammar school. Here are key details about the Bucks 11+ test:</p>


    <p><strong>Registration closing date: </strong> June (for those attending a private school, a partner school or a primary school outside of Bucks)</p>
    <p><strong>Common Application Form (CAF) closing date:</strong> October</p>
    <p><strong>Exam date:</strong> September</p>
    <p><strong>Exam board type: </strong> GL Assessment</p>
    <p><strong>Pass mark: </strong> Aim for 75%+ in our practice tests. See below for more details.</p>
    <p><strong>Results date: </strong> October</p>
    <p><strong>Admissions info: </strong> <a href="https://www.buckinghamshire.gov.uk/schools-and-learning/schools-index/school-admissions/grammar-schools-and-transfer-testing-11/">Buckinghamshire County Council</a>, 01296 395000</p>

    <div class="spacer"></div>
    <div class="spacer"></div>

    <h2>Buckinghamshire Grammar Schools 11 Plus (11+) Admissions</h2>

    <div class="spacer"></div>

    <p>Admission to Buckinghamshire grammar schools in Year 7 is determined by student performance in the 11 plus Secondary Transfer Test, which aims to select students based on academic ability. Admission into Year 7 at all grammar schools in the county is managed by Buckinghamshire County Council.</p>

    <p>Each of the Buckinghamshire grammar schools hosts a range of open events, often in the summer term, providing prospective pupils and parents with the opportunity to take a tour of the campus, meet senior staff and speak to current pupils.</p>

    <div class="spacer"></div>

    <h2>How to Register for the 11 Plus (11+) Exam at Buckinghamshire Grammar Schools</h2>

    <div class="spacer"></div>

    <p>Pupils who currently attend a primary school in Buckinghamshire are automatically registered to take the county’s 11 Plus Secondary Transfer Test. </p>

    <p>Those who don’t live in Buckinghamshire but wish to attend a school in the county from Year 7 must be registered for the exam using the Bucks online application portal.</p>

    <p>If relevant to their child’s 11+ application, parents should also complete the Supplementary Information Form and send it to their preferred school.</p>

    <div class="spacer"></div>

    <p>The exam board for the 11+ test in Buckinghamshire is GL Assessment. The Buckinghamshire Secondary Transfer Test is split into two papers that test:</p>

    <div class="spacer"></div>

    <ol>
      <li>Comprehension, technical English and Verbal Reasoning</li>
      <li>Non-Verbal Reasoning, Spatial Reasoning and Mathematics</li>
    </ol>

    <div class="spacer"></div>

    <p>Each test paper lasts for approximately 45 minutes and all questions are multiple-choice. The 11+ Secondary Transfer Test is weighted as follows:</p>

    <div><img src="assets/images/check-list.png" alt=""> <p>Verbal Ability – 50%</p></div>

    <div><img src="assets/images/check-list.png" alt=""> <p>Non-Verbal Ability – 25%</p></div>

    <div><img src="assets/images/check-list.png" alt=""> <p>Numerical Ability – 25%</p></div>

    <div class="spacer"></div>

    <p>The 11 Plus Secondary Transfer Test is taken by students in September of Year 6, with all children taking the test on the same day, whether they are within the Buckinghamshire catchment area or not. The tests are age standardised to ensure that no students have an advantage based on their age.</p>

    <p>Achieving the Secondary Transfer Test Score (STTS) does not guarantee a place at the school. Each grammar school in Buckinghamshire sets its own additional admissions criteria.</p>

    <p>Before taking the Secondary Transfer Test, students will have the opportunity to take a practice test, two days before the actual exam. The practice test aims to give students the experience of taking a test under exam conditions and to help familiarise them with the layout of the 11+ STT.</p>

    <p>For more information on the Secondary Transfer Test, please see the Buckinghamshire County Council website.</p>

    <div class="spacer"></div>

    <h2>Buckinghamshire 11 Plus (11+) Exam Pass Marks and Target Scores</h2>

    <div class="spacer"></div>

    <p>All candidates with a standardised score above 121 will qualify for a Bucks grammar school place. </p>

    <p>Based on historical data, we have found that students who are able to score 75%+ consistently in our 11+ Bucks practice tests are on track to pass the STT. </p>

    <p>Whilst there are no guarantees, this has historically been a fairly strong indicator that your child should do well in the Bucks 11+ exam.</p>

    <div class="spacer"></div>

    <p>Passing the test does not guarantee that your child will be offered a place. It simply means they have surpassed the required threshold. As such, it makes sense to aim for as high a score as possible.</p>

    <p>Don’t worry if your child is not scoring well in our practice papers from the outset. With targeted practice and focus, it’s possible to make big improvements in a short amount of time!</p>

    <div class="spacer"></div>

    <h2>Buckinghamshire Grammar Schools: Results and Leavers’ Destinations </h2>

    <div class="spacer"></div>

    <h4>Buckinghamshire Grammar Schools – Progress 8 Scores</h4>

    <div class="spacer"></div>

    <p>Progress 8 is a metric used to evaluate the progress made by students between the end of primary school (Key Stage 2) and the end of secondary school (Key Stage 4). It serves as a means of assessing the value that schools contribute to their students’ education, based on their academic advancement.</p>

    <p>📊 Please refer to the charts below for details on Progress 8 scores at grammar schools in Bucks:</p>

    <img src="assets/images/chart-bks.png" alt="">

    <p>A positive Progress 8 score indicates that students, on average, have made more progress than expected, while a negative score suggests that they have made less progress than expected. A score of zero means that students have progressed as expected.</p>

    <div class="spacer"></div>

    <h4>Buckinghamshire Grammar Schools – Leavers’ Destinations</h4>

    <p>Leavers’ destinations here refer to the next steps that students take after completing their studies at Buckinghamshire grammar schools. </p>

    <p>Russell Group universities are esteemed educational institutions, widely acclaimed for their exceptional achievements. They showcase remarkable research output, housing esteemed scholars and groundbreaking discoveries. Their academic reputation is exemplary, drawing talented individuals from across the globe.</p>

    <p>Among the esteemed members of the Russell Group are the University of Oxford and the University of Cambridge, both renowned for their illustrious history, academic excellence and rigorous educational programmes. With distinguished faculty and a commitment to excellence, these universities have maintained their prestigious reputation for centuries.</p>

    <p>📊 The charts below show the recent leavers’ destinations for grammar schools in Bucks:</p>

    <img src="assets/images/chart-2-bks.png" alt="">

    <div class="spacer"></div>

    <h2>Frequently Asked Questions About The 11 Plus (11+) Exam in Buckinghamshire</h2>


    <div class="spacer"></div>

   <p> <strong>Does Buckinghamshire have grammar schools? </strong>
    <br> 
    <br>Yes, Buckinghamshire has 13 selective grammar schools, which use the same 11+ exam to assess Year 7 applicants. This exam is known as the Secondary Transfer Test (STT). Click here to access the articles we have for each of these schools, addressing their history and ethos, as well as all the details about their 11+ entry procedure.</p>

    <div class="spacer"></div>

    <p> <strong>When is the closing date for registrations at grammar schools in Buckinghamshire? </strong>
      <br> 
      <br>The deadline for registering for the Bucks STT is in June, but only students who don’t live in Buckinghamshire are required to register. Pupils who currently attend a primary school in Buckinghamshire are automatically registered to take the county’s 11 plus. </p>


      <div class="spacer"></div>

    <p> <strong>When is the date of the Secondary Transfer Test (STT) in Buckinghamshire?</strong>
      <br> 
      <br>The Bucks 11+ Secondary Transfer Test (STT) exam is held in September.</p>


      <div class="spacer"></div>

    <p> <strong>What is evaluated in the Buckinghamshire 11+ Secondary Transfer Test?</strong>
      <br> 
      <br>The Bucks Test consists of two multiple-choice papers lasting 45 minutes each. These assess:
 
      – Paper 1: Comprehension, technical English and Verbal Reasoning
      – Paper 2: Non-Verbal Reasoning, Spatial Reasoning and Mathematics.</p>


      <div class="spacer"></div>

    <p> <strong>Who is the 11+ exam provider for Buckinghamshire?</strong>
      <br> 
      <br>Grammar schools in Buckinghamshire use the GL Assessment exam provider. </p>


  </div>

</div>

</div>