<div class="cs-head" style="background:url(assets/images/c_bg.jpg);">

  <h1>What is 11plus? </h1>

</div>

<!-- 1 -->

<div class="section-1">

  <img src="assets/images/p4.jpg" alt="">

  <div class="sub-1">

    <h1>What is the Eleven Plus?</h1>

    <p>The 11 Plus (also known as the 11+ or Eleven Plus) is an exam taken by some students in their final year of primary school to gain admission to a grammar school of their choice. Despite the name, most children are only 10 years old when they sit for the test—the term "11 Plus" refers to the fact that the exam selects students for schools with an entry age of 11 or older. <br> <br>

      Currently, there are 164 grammar schools left in England. The 11 Plus test was officially abolished in Northern Ireland in 2008, but many former grammar schools there continue to use tests to select students based on ability, a practice that has sparked significant political controversy.<br> <br>
      
      The qualification rate for the 11 Plus varies widely across the country. Some schools receive thousands of applications for as few as 180 places—an example being the four grammar schools in Kingston and Sutton, where the pass rate can be as low as 3 percent. In regions where the grammar school system is fully intact, the pass rate is significantly higher—around 30 percent annually in Buckinghamshire.<br> <br>
      
      The 11 Plus tests can include up to four different subjects: Verbal Reasoning, Non-Verbal Reasoning, Maths, and English. The specific combination of test papers used varies significantly depending on the region.
      </p>



  </div>

</div>

<!-- 2 -->

<div class="section-1">

 

  <div class="sub-1">

    <h1>What is the intention behind the 11 plus exam?</h1>

    <p>The 11 Plus (11+) is an entrance exam used by grammar schools in England, which are state-funded secondary schools that select students based on academic ability. Taken in the autumn of Year 6, the 11 Plus is used to apply for a place in Year 7 at a grammar school. <br> <br>

      The exam is designed to assess your child's proficiency in core subjects and gauge their academic potential. Schools use the results to determine which students are most likely to excel in their environment. <br> <br>
      
      Private senior school entrance exams are sometimes also called the 11 Plus. While the subjects tested are similar, the application process and level of difficulty may differ. <br> <br>
      </p>


  </div>

  <img src="assets/images/p2.jpg" alt="">

</div>


<!-- 3 -->

<div class="section-1">

  <img src="assets/images/p7.jpg" alt="">

  <div class="sub-1">

    <h1>Which subjects are included in the 11 plus exam?</h1>

    <p>Standard 11 Plus exams typically cover any combination of the following four subjects:
      </p>

      <ul>
        <li><p style="font-weight: bold;">English</p></li>
        <li><p style="font-weight: bold;">Maths</p></li>
        <li><p style="font-weight: bold;">Verbal reasoning</p></li>
        <li><p style="font-weight: bold;">Non-verbal reasoning</p></li>
      </ul>

      <p>Not all schools or regions use all four subjects; they can mix these with their own test content. <br> <br>

        The English and maths sections of the 11 Plus align with Key Stage 2 national curriculum objectives, sometimes including Year 6 content that children may not have encountered yet. <br> <br>
        
        Verbal and non-verbal reasoning, however, are not part of the national curriculum, so students in state primary schools typically won't learn these subjects in class. Instead of testing acquired knowledge, reasoning exams evaluate cognitive ability, assessing children's capacity to think critically, recognize patterns, identify connections, and solve problems.</p>


  </div>

</div>


<!-- 4 -->

<div class="section-1">

 

  <div class="sub-1">

    <h1>What is the structure of the 11 Plus exam?</h1>

    <p>The 11 Plus is typically a paper-based exam, with most tests being multiple-choice. In this format, students receive a separate question booklet and pre-printed answer sheets. They select their answers by shading in the corresponding option on the answer sheet, which is then scanned and marked by a computer using Optical Mark Recognition (OMR).
      </p>

  </div>

  <img src="assets/images/p5.jpg" alt="">

</div>


<!-- 5 -->

<div class="section-1">

  <img src="assets/images/p8.jpg" alt="">

  <div class="sub-1">

    <h1>11plus Exam Providers</h1>

    <p>Most grammar schools in England use 11 Plus exams from the following providers:</p>

    <ul>
      <li><p style="font-weight: bold;">GL Assessment: The most widely used 11 Plus provider. GL Assessment exams are entirely multiple-choice and can include any combination of English, maths, verbal reasoning, and non-verbal reasoning.
      </p></li>

      <li><p style="font-weight: bold;">FSCE: A smaller 11 Plus provider used by a few grammar schools. FSCE exams include English comprehension, maths, and creative writing papers, but do not test reasoning.
      </p></li>
    </ul>

    <p>Some schools or regions use custom exams. For example, 10 grammar schools in Essex use papers set by the Consortium of Selective Schools in Essex (CSSE), which creates its own 11 Plus exams. St. Olaves </p>

  </div>

 

</div>


<!-- 6 -->

<div class="section-1">

  <div class="sub-1">

    <h1>How many questions are on the 11 Plus?</h1>

    <p>The number of questions and the timing of the 11 Plus are determined by the local authority, consortium, or individual school. In some cases, multiple subjects may be combined into a single paper.</p>

    <p>In GL Assessment 11 Plus papers, the most common arrangement is:</p>
    <ul>
      <li><p style="font-weight: bold;">English: 25 reading comprehension questions and 8–10 spelling, punctuation, and grammar questions
      </p></li>

      <li><p style="font-weight: bold;">Maths: 50 questions
      </p></li>

      <li><p style="font-weight: bold;">Verbal reasoning: 80 questions
      </p></li>

      <li><p style="font-weight: bold;">Non-verbal reasoning: 80 questions

      </p></li>
    </ul>

    <p>Some schools or regions use custom exams. For example, 10 grammar schools in Essex use papers set by the Consortium of Selective Schools in Essex (CSSE), which creates its own 11 Plus exams. St. Olaves </p>

  </div>

  <img src="assets/images/p11.jpg" alt="">

</div>

<!-- 7 -->

<div class="section-1">

  <img src="assets/images/p9.jpg" alt="">

  <div class="sub-1">

    <h1>What are 11plus Exam stages?</h1>

    
    <ul>
      <li><p style="font-weight: bold;">1. Exam preparation - starts as soon as a child joins school year 05

      <li><p style="font-weight: bold;">2. Exam Registration - May/Jun when the student is in School Year 05
      </p></li>

      <li><p style="font-weight: bold;">3. Main Exam - Sep/Oct – Student is in Year 06
      </p></li>

      <li><p style="font-weight: bold;">4. Indicative Result is out - Oct
      </p></li>

      <li><p style="font-weight: bold;">5. Common Application Form (CAF) - Parents to select top 5/6 schools in preference order, based on indicative results; deadline Oct 31st
      </p></li>

      <li><p style="font-weight: bold;">6. National Offer Day - 1st Mar following year
      </p></li>

      <li><p style="font-weight: bold;">7. School Entry Year 07 - Sep following year
      </p></li>
      

    </ul>


  </div>

</div>


<!-- 8 -->

<div class="section-1">

 

  <div class="sub-1">

    <h1>TEST TYPES</h1>

    
    <ul style="padding: 20px;">
      <li><p style="font-weight: bold;">GL Redbridge</p>

        <ol style="list-style: circle; padding-left: 50px;">
          <li><p>Two Multiple Choice Papers – Mixed</p></li>
          <li><p>New Format introduced</p></li>
        </ol>
        
      </li>

      <li><p style="font-weight: bold;">CSSE</p>

        <ol style="list-style: circle; padding-left: 50px;">
          <li><p>Two Subjective Papers – Maths and English
          </p></li>
          <li><p>Demanding English; Creative Writing </p></li>
        </ol>
        
      </li>

      <li><p style="font-weight: bold;">GL (Kent, QE, HBS)
      </p>

        <ol style="list-style: circle; padding-left: 50px;">
          <li><p>Multiple Choice – Maths, English, VR, NVR
          </p></li>
          <li><p>Different flavours </p></li>
          <li><p>Relatively High Scoring</p></li>
        </ol>
        
      </li>


      <li><p style="font-weight: bold;">FSCE
      </p>

        <ol style="list-style: circle; padding-left: 50px;">
          <li><p>Maths Paper - Multiple Choice + Subjective 
          </p></li>
          <li><p>English Paper - Multiple Choice + Subjective</p></li>
          <li><p>Creative Writing</p></li>
        </ol>
        
      </li>

      
      

    </ul>


  </div>

  <img src="assets/images/p10.jpg" alt="">

</div>

<!-- 9 -->

<div class="strip-1">

  <h3>For More Information on Exam, Please Visit Our Consortims.</h3>

  <div class="btn-container">

    <button routerLink = '/csse-schools'>
      CSSE Consortium
    </button>

    <button routerLink = '/redbridge-schools'>
      Rebridge Consortium
    </button>

    <button routerLink = '/kent'>
      Kent Consortium
    </button>

    <button style="background-color: #0066FF" routerLink = '/consortium'>
     All Consortiums
    </button>

  
  </div>

  <div class="sep-line"></div>

  <h3>For Exam Prepration Visit our Targeted Modules</h3>


<div class="btn-container">

    <button routerLink = '/11Plus-classes'>
      11plus Classes
    </button>

    <button routerLink = '/11Plus-mocktest'>
      11plus Mock Test
    </button>

    <button style="background-color: #0066FF" routerLink = '/product-services'>
      Products & Services
     </button>

  
  </div>



  
</div>







