import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-redbridge',
  templateUrl: './page-redbridge.component.html',
  styleUrls: ['./page-redbridge.component.css']
})
export class PageRedbridgeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


  schools = [
    { name: 'Ilford County High School', url: 'https://www.ichs.org.uk/' },
    { name: 'Woodford County High School for Girls', url: 'http://www.woodford.redbridge.sch.uk/' },
  ]

}
