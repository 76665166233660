<div class="clearfix"></div>
<div class="cs-head" style="background:url(assets/images/c_bg.jpg);">

  <h1>11+ Consortiums</h1>

</div>



<div class="exam-container">
  <div class="exam-item" *ngFor="let exam of examList; let i = index">
    <div class="exam-content">
      <h2>{{ exam.title }}</h2>
      <p>{{ exam.description }}</p>
      <button class="read-more" (click)="openExam(i)">Read More</button>
    </div>
    <div class="exam-image">
      <img [src]="exam.image" alt="{{ exam.title }}">
    </div>
  </div>
</div>






