<div class="cs-head" style="background:url(assets/images/c_bg.jpg);">

  <h1>Grammar Schools </h1>

</div>

<div class="school-list">

  <div *ngFor="let school of schools" class="school-card">
    <span>{{ school.name }}</span>
    <a [href]="school.website" target="_blank">
      <button class="visit-btn">Visit</button>
    </a>
  </div>
  
</div>






