import { MonthTimetable } from '../models/event.model';
import * as i0 from "@angular/core";
var TimetableService = /** @class */ (function () {
    function TimetableService() {
        this.timetableData = {
            'February': [
                {
                    date: 'Sat 08',
                    name: 'Mock Test',
                    description: 'Full Mock | Maths | Paper-2 (Calc)',
                    testtype: "",
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 3:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/a0ad593c877d920bc925d9934a66f15d' },
                    ]
                },
                {
                    date: 'Sun 09',
                    name: 'Mock Test',
                    description: 'Full Mock | Maths | Paper-2 (Calc)',
                    testtype: "",
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/d17bc1dd1262bb6bb19f36431e81da2c' },
                        { name: 'Basildon', time: '4:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/9b2f63849a594f6a5a8f97cf9dc015aa' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/5a53e9120e0faced511df34a9cafa392' },
                        {
                            name: 'Online', time: '9:00 AM - 6:00 PM',
                            examOptions: {
                                EDEXCEL: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/48410d81de98bfb6dede76521b2c1c9a'
                            }
                        }
                    ]
                },
                {
                    date: 'Sun 15',
                    name: 'Mock Test',
                    description: 'Full Mock | Maths | Paper-3 (Calc)',
                    testtype: "",
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 3:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/6a8e26e10d536252c6654593fe5bb77c' },
                    ]
                },
                {
                    date: 'Sun 16',
                    name: 'Mock Test',
                    description: 'Full Mock | Maths | Paper-3 (Calc)',
                    testtype: "",
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/d41e8db3ff598b9e43d3c6a5783c42a8' },
                        { name: 'Basildon', time: '4:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/5d9895611517786fb024558792c6b3fe' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/d6bf663f59c56921f8c8f7f2a73e07be' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', examOptions: {
                                EDEXCEL: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/dbbb73a062dfd0f20da83d2aba8f6bbc'
                            } }
                    ]
                },
                {
                    date: 'Sun 22',
                    name: 'Mock Test',
                    testtype: "",
                    description: 'Full Mock | Physics | Paper-1',
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 3:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/6c21c613d2ccac80af03f1361e9bd43a' },
                    ]
                },
                {
                    date: 'Sun 23',
                    name: 'Mock Test',
                    testtype: "",
                    description: 'Full Mock | Physics | Paper-1',
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/15c770f5caaf5932b1ec82b7b2ebcc28' },
                        { name: 'Basildon', time: '4:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/d714bb6729e4ed30775c1aba95ec3c79' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/b3dd386e791d30fc6b3f6d9f11ff320d' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', examOptions: {
                                AQA: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/8ad29ed62f07a10dc8cbedc5b47c739e',
                                OCR: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/d08b92c70a4124c8fc7bdc4a8339a529',
                                EDEXCEL: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/9b22aad1f0cde21d12c1e3178bf399c4'
                            } }
                    ]
                },
            ],
            'March': [
                {
                    date: 'Sat 01',
                    name: 'Mock Test',
                    description: 'Full Mock | Physics| Paper-2',
                    testtype: "",
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 03:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/0b5bfa1a9439ea58158cbc1d19d27dd6' },
                    ]
                },
                {
                    date: 'Sun 02',
                    name: 'Mock Test',
                    description: 'Full Mock | Physics| Paper-2',
                    testtype: "",
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/8ee55312f865590a905bbe7d3e9e14b7' },
                        { name: 'Basildon', time: '04:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/2ad14c345a933925b98100a1e7ab350c' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/b58942f119cab7cd1a8b8242c592821b' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', examOptions: {
                                AQA: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/e1495e23d455075b40e00cd573dc5cf1',
                                OCR: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/a54faa3a8ac4f2df872cdac3e51d9579',
                                EDEXCEL: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/89a64f94525d2a08c8b2825a09b12792'
                            } }
                    ]
                },
                {
                    date: 'Sat 08',
                    name: 'Mock Test',
                    description: 'Full Mock | Chemistry | Paper-1',
                    testtype: "",
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 03:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/d631d3873edf45d5ad6390657804345b' },
                    ]
                },
                {
                    date: 'Sun 09',
                    name: 'Mock Test',
                    description: 'Full Mock | Chemistry | Paper-1',
                    testtype: "",
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/1e2dcf8758a78c7e9afd124001425741' },
                        { name: 'Basildon', time: '4:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/1a6dcf360bc490f3a3c7fb5a0ca30bb9' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/20badf2b2474e15c191924422ac75c5a' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', examOptions: {
                                AQA: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/8160217cdd6fa360981359cedbd0328d',
                                OCR: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/379f0e634bde3fdee3fd8cfaa5fd1764',
                                EDEXCEL: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/b6bbbf1ca993ec0e37a52785225400db'
                            } }
                    ]
                },
                {
                    date: 'Sat 15',
                    name: 'Mock Test',
                    description: 'Full Mock | Chemistry | Paper-2',
                    testtype: "",
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 03:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/1571187e828b7c1a1813a423a68f9364' },
                    ]
                },
                {
                    date: 'Sun 16',
                    name: 'Mock Test',
                    description: 'Full Mock | Chemistry | Paper-2',
                    testtype: "",
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/643093c95a45a257356d4b70c33e82fa' },
                        { name: 'Basildon', time: '4:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/cd6e61cd26a2697326ae7af95d9ce66c' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/12fd59bcd57bbe883dbd86f792830bc2' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', examOptions: {
                                AQA: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/08ae083b40ee40d976620b13c95f3f5b',
                                OCR: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/382e14b4d096562e802f0d7b258754ac',
                                EDEXCEL: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/3d3e6f07374dff2c071558e2abc4d7f2'
                            } }
                    ]
                },
                {
                    date: 'Sat 29',
                    name: 'Mock Test',
                    description: 'Full Mock | Biology | Paper-1',
                    testtype: "",
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 03:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/f982bbb78037c15949b91b8b5b9217a1' },
                    ]
                },
                {
                    date: 'Sun 30',
                    name: 'Mock Test',
                    description: 'Full Mock | Biology | Paper-1',
                    testtype: "",
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/35f60ed4a122c61897dd6368e46cdb54' },
                        { name: 'Basildon', time: '04:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/07bb205b3d6ac096ebbc7607ad67a5cb' },
                        { name: 'Amersham', time: '03:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/42f5460e010bdca671b69dc68f3301e5' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', examOptions: {
                                AQA: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/3909194e712641520daa856fdb746f84',
                                OCR: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/b6f1dfdc0f77ff4e1fa8a0689d9a6e8c',
                                EDEXCEL: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/6552eeba00ddf0bde00ce9d870dcfc6b'
                            } }
                    ]
                }
            ],
            'April': [
                {
                    date: 'Sat 05',
                    name: 'Mock Test',
                    description: 'Full Mock | Biology| Paper-2',
                    testtype: "",
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 3:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/b80e74b6a086abc47c87cd756d1377a7' },
                    ]
                },
                {
                    date: 'Sun 06',
                    name: 'Mock Test',
                    description: 'Full Mock | Biology| Paper-2',
                    testtype: "",
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/6ea782c513b1bafb2d50b393fbef5427' },
                        { name: 'Basildon', time: '04:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/7fec2568f694ce46254216a936e64c60' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/8653352e9898d4a176896725264369fb' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', examOptions: {
                                AQA: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/1be73ba6dbbc79a2ee0f63fcf86f656a',
                                OCR: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/5fa1baf673d596176b0c7f82ea86da15',
                                EDEXCEL: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/eb31dcc8ca706034cb4057d0e5524b55'
                            } }
                    ]
                },
                {
                    date: 'Sat 12',
                    name: 'Mock Test',
                    description: 'Full Mock | English| Paper-1 & Paper-3',
                    testtype: "",
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 03:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/1199b61fa16038d4ca73daf3200550d2' },
                    ]
                },
                {
                    date: 'Sun 13',
                    name: 'Mock Test',
                    description: 'Full Mock | English| Paper-1 & Paper-3',
                    testtype: "",
                    centers: [
                        { name: 'Ilford', time: '5:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/31a16135e5ed0fb676de7081be1cb3d6' },
                        { name: 'Basildon', time: '4:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/e94ef9cae554ced507e2c050ac99d83f' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/4006d6a23c7787ab5132f114f512f06a' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', examOptions: {
                                AQA: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/84fb25682065403fe9670f9c7de62b8d',
                                OCR: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/84da5aeca031e743e918d549ed87467c',
                                EDEXCEL: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/edexcel-link'
                            } }
                    ]
                },
                {
                    date: 'Sat 19',
                    name: 'Mock Test',
                    description: 'Full Mock | English| Paper-2 & Paper-4',
                    testtype: "",
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 03:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/8b06efc21bf0c534c27606de737c61e7' },
                    ]
                },
                {
                    date: 'Sun 20',
                    name: 'Mock Test',
                    description: 'Full Mock | English| Paper-2 & Paper-4',
                    testtype: "",
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/70c597bca7a178404579271c7af72cc0' },
                        { name: 'Basildon', time: '4:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/a9288dcf8e709dedf419ef33a2322f9e' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/a450e858dc1a6cd2564fe3ce27fd30e5' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', examOptions: {
                                AQA: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/aqa-link',
                                OCR: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/ocr-link',
                                EDEXCEL: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/edexcel-link'
                            } }
                    ]
                },
                {
                    date: 'Sun 26',
                    name: 'Mock Test',
                    description: 'Full Mock | Computer | Paper-1 & Paper-2',
                    testtype: "",
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 3:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/b9c66a6c5e46e5b8896f0aac7a693230' },
                    ]
                },
                {
                    date: 'Sun 27',
                    name: 'Mock Test',
                    description: 'Full Mock | Computer | Paper-1 & Paper-2',
                    testtype: "",
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/cfbaea1ceba9e909d7529006c80eb472' },
                        { name: 'Basildon', time: '4:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/975be5defa34afbe79d9edad144b375f' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/0c8dd6de7778ed6f5ec8ac5ed97645b4' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', examOptions: {
                                AQA: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/728454439d495f43353e1fea56a0e8f8',
                                OCR: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/0f919157e68ddefed7b5513a9f2a5d2a',
                                EDEXCEL: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/edexcel-link'
                            } }
                    ]
                },
            ],
        };
    }
    TimetableService.prototype.getMonths = function () {
        return ['February', 'March', 'April',];
    };
    TimetableService.prototype.getTimetable = function () {
        return this.timetableData;
    };
    TimetableService.ngInjectableDef = i0.defineInjectable({ factory: function TimetableService_Factory() { return new TimetableService(); }, token: TimetableService, providedIn: "root" });
    return TimetableService;
}());
export { TimetableService };
