import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { title } from 'process';

@Component({
  selector: 'app-consortium',
  templateUrl: './consortium.component.html',
  styleUrls: ['./consortium.component.css']
})
export class ConsortiumComponent {

  examList = [

    {
      title: "Consortium of Selective Schools in Essex (CSSE) 11 Plus (11+) Exam 2024, Year 7 Entry – Key Details",
      description: "The Consortium of Selective Schools in Essex (CSSE) is a collaborative group of grammar schools in Essex that work together to administer the 11+ entrance examination for admission to their schools. The CSSE aims to ensure a fair and consistent admissions process for selective schools in Essex, allowing students to be considered for multiple schools within the consortium based on their performance in the entrance exam.",
      image: "assets/images/csse_sch.jpeg",
      route: "csse-schools"
    },

    {
      title:"Redbridge, Essex, Grammar Schools 11 Plus (11+) Exam Information",
      description:"In the London Borough of Redbridge, Essex, there are two well-known grammar schools. Both offer a similarly high level of education and provide a wealth of academic and non-academic opportunities, enabling students to fulfil their ambitions.",
      image:"assets/images/redb-sch.png",
      route:"redbridge-schools"
    },

    {
      title: "Buckinghamshire Grammar School Consortium 11 Plus (11+) Exam Information",
      description: "The Buckinghamshire Grammar School Consortium was established with the purpose of coordinating the 11+ secondary school entrance examination process in Buckinghamshire. Its objective is to ensure a fair and consistent admissions process for all selective grammar schools in the county. Students who take the 11+ entrance exam have the opportunity to be considered for multiple schools within the consortium based on their performance",
      image: "assets/images/bks-sch.jpeg",
      route: "bucks-schools"
    },

    {
      title: "Kent Grammar Schools 11 Plus (11+) Exam Information",
      description: "The Consortium of Selective Schools in Essex (CSSE) is a collaborative group of grammar schools in Essex that work together to administer the 11+ entrance examination for admission to their schools. The CSSE aims to ensure a fair and consistent admissions process for selective schools in Essex, allowing students to be considered for multiple schools within the consortium based on their performance in the entrance exam.",
      image: "assets/images/sch-1.jpg",
      route: "kent"
    },
    // {
    //   title: "Buckinghamshire Grammar School Consortium 11 Plus (11+) Exam Information",
    //   description: "The Consortium of Selective Schools in Essex (CSSE) is a collaborative group of grammar schools in Essex that work together to administer the 11+ entrance examination for admission to their schools. The CSSE aims to ensure a fair and consistent admissions process for selective schools in Essex, allowing students to be considered for multiple schools within the consortium based on their performance in the entrance exam.",
    //   image: "assets/images/buckinghamshire.jpg"
    // },
    // {
    //   title: "Queen Elizabeth’s School, Barnet, 11 Plus (11+) Exam – Key Details 2025",
    //   description: "The Consortium of Selective Schools in Essex (CSSE) is a collaborative group of grammar schools in Essex that work together to administer the 11+ entrance examination for admission to their schools. The CSSE aims to ensure a fair and consistent admissions process for selective schools in Essex, allowing students to be considered for multiple schools within the consortium based on their performance in the entrance exam.",
    //   image: "assets/images/queen-elizabeth.jpg"
    // }
  ];

  images: string[] = [
    'assets/images/sch-1.jpg',
    'assets/images/sch_2.jpg',
    'assets/images/sch-3.png',
    'assets/images/sch-4.jpg',
  ];
  currentIndex = 0;
  interval: any;

  constructor(private router: Router) {
    this.autoSlide();
  }

  goToSlide(index: number) {
    this.currentIndex = index;
  }

  autoSlide() {
    this.interval = setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    }, 3000); // Auto-slide every 3 seconds
  }

  openExam(index:number){
    this.router.navigate([this.examList[index].route])
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
