import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-page-one',
  templateUrl: './page-one.component.html',
  styleUrls: ['./page-one.component.css'],
  animations: [
    trigger('toggleAnimation', [
      state('closed', style({ height: '0px', opacity: 0, display: 'none' })),
      state('open', style({ height: '*', opacity: 1, display: 'block' })),
      transition('closed <=> open', [
        animate('300ms ease-in-out')
      ])
    ])
  ]
})
export class PageOneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  schools = [
    { name: 'Highsted School', url: 'https://www.highsted.kent.sch.uk' },
    { name: 'The Judd School', url: 'https://www.judd.online' },
    { name: 'Cranbrook School', url: 'https://www.cranbrookschool.co.uk' },
    { name: 'The Skinners’ School', url: 'https://www.skinners-school.co.uk' },
    { name: 'Borden Grammar School', url: 'https://www.bordengrammar.kent.sch.uk' },
    { name: 'Barton Court Grammar School', url: 'https://www.bartoncourt.org' },
    { name: 'Chatham & Clarendon Grammar School', url: 'https://www.ccgrammarschool.co.uk' },
    { name: 'Dane Court Grammar School', url: 'https://www.danecourt.kent.sch.uk' },
    { name: 'Dartford Grammar School', url: 'https://www.dartfordgrammarschool.org.uk' },
    { name: 'Dartford Grammar School for Girls', url: 'https://www.dartfordgrammargirls.kent.sch.uk' },
    { name: 'Dover Grammar School for Boys', url: 'https://www.dovergramboys.kent.sch.uk' },
    { name: 'Dover Grammar School for Girls', url: 'https://www.dggs.kent.sch.uk' },
    { name: 'Gravesend Grammar School', url: 'https://www.gravesendgrammar.com' },
    { name: 'Highworth Grammar School', url: 'https://www.highworth.kent.sch.uk' },
    { name: 'Invicta Grammar School', url: 'https://www.invicta.viat.org.uk' },
    { name: 'Maidstone Grammar School', url: 'https://www.mgs.kent.sch.uk' },
    { name: 'Maidstone Grammar School for Girls', url: 'https://www.mggs.org' },
    { name: 'Mayfield Grammar School', url: 'https://www.mgsg.kent.sch.uk' },
    { name: 'Oakwood Park Grammar School', url: 'https://www.opgs.org' },
    { name: 'Queen Elizabeth’s Grammar School, Faversham', url: 'https://www.queenelizabeths.kent.sch.uk' },
    { name: 'Simon Langton Girls’ Grammar School', url: 'https://www.langton.kent.sch.uk' },
    { name: 'Simon Langton Grammar School for Boys', url: 'https://www.thelangton.org.uk' },
    { name: 'Sir Roger Manwood’s School', url: 'https://www.srms.kent.sch.uk' },
    { name: 'The Folkestone School for Girls', url: 'https://www.folkestonegirls.kent.sch.uk' },
    { name: 'The Harvey Grammar School', url: 'https://www.harveygs.kent.sch.uk' },
    { name: 'The Norton Knatchbull School', url: 'https://www.nks.kent.sch.uk' },
    { name: 'Tonbridge Grammar School', url: 'https://www.tgs.kent.sch.uk' },
    { name: 'Tunbridge Wells Girls’ Grammar School', url: 'https://www.twggs.kent.sch.uk' },
    { name: 'Tunbridge Wells Grammar School for Boys', url: 'https://www.twgsb.org.uk' },
    { name: 'Weald of Kent Grammar School', url: 'https://www.wealdofkent.kent.sch.uk' },
    { name: 'Wilmington Grammar School for Boys', url: 'https://www.wgsb.org.uk' },
    { name: 'Wilmington Grammar School for Girls', url: 'https://www.wgsg.co.uk' }
  ];

  faqItems = [
    { question: 'Does Kent have grammar schools?', answer: 'Yes, Kent has 32 selective grammar schools, which use the same 11+ exam, known as the Kent Test, to assess Year 7 applicants. Click here to access the articles we have for each of these schools, addressing their history and ethos, as well as all the details about their 11+ entry procedure.', open: false },
    { question: 'When is the Kent 11+ registration deadline?', answer: 'The deadline for registering for the Kent Test is in July of the year preceding entry. You must also submit the Secondary School Common Application Form (SCAF) prior to the October deadline, indicating your child’s preferred school/schools.', open: false },
    { question: 'When is the date of the 11+ Kent Test?', answer: 'The 11+ Kent Test is held in September.', open: false },
    { question: 'What is assessed in the Kent Test?', answer: 'The Kent Test consists of an hour-long exam that’s divided into two sections that cover English and Mathematics, and an hour-long exam that focuses on Reasoning. All questions are multiple-choice format. Pupils are also required to complete a 40-minute writing task, but this is almost never marked or taken into account.', open: false },
    { question: 'What is the pass mark for the Kent grammar schools?', answer: 'In order to pass the exam, your child’s single score must be over 109 and their total score must be 332 or more. Based on historical data, students who are able to score 75%+ consistently in our 11+ Kent practice tests are on track to pass the Kent Test. ', open: false },
    { question: 'Who is the 11+ exam provider for Kent?', answer: 'Grammar schools in Kent use the GL Assessment exam provider. ', open: false }
  ];

  toggle(item) {
    item.open = !item.open;
  }

}
